import React, { useEffect } from "react";
import {
  Autocomplete,
  Card,
  CardContent,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Axios } from "app/services/config";
import ToastAlerts from "app/components/Toast";
import { isValidEmail } from "@jumbo/utils";
import { onRoomList } from "app/redux/actions/Room";
import { useState } from "react";

const AddGuest = () => {
  const navigate = useNavigate();
  const showAlert = ToastAlerts();
  const dispatch = useDispatch();
  // const [gender, SetGender] = useState(["Male", "Female"]);
  const [category, SetCategory] = useState(["VIP", "Guest"]);
  const [foodPreference, setfoodPreference] = useState([
    "Veg",
    "Jain",
    "Non-Veg",
  ]);

  var initialValues = {
    full_name: "",
    designation: "",
    email_id: "",
    category: "",
    company_name: "",
    mobile_number: "",
    cha_license_number: null,
    food_preference: null,
    gst_number: null,
    status: true,
  };

  // const validationSchema = yup.object({
  //   first_name: yup
  //     .string("Enter First Name")
  //     .required("First Name is required")
  //     .matches(
  //       /^[A-Za-z ]+$/,
  //       "First Name must contain only alphabetic characters"
  //     ),
  //   // last_name: yup
  //   //   .string("Enter Last Name")
  //   //   .required("Last Name is required")
  //   //   .matches(
  //   //     /^[A-Za-z ]+$/,
  //   //     "Last Name must contain only alphabetic characters"
  //   //   ),
  //   email_id: yup
  //     .string("Enter your Email ID")
  //     .required("Email is required")
  //     .test(
  //       "isValidEmail",
  //       "Email should contain valid characters, an '@' symbol",
  //       (value) => isValidEmail(value)
  //     ),
  //   category: yup
  //     .string("Enter your Category")
  //     .required("Category is required"),
  //   // gender: yup.string("Enter your Gender").required("Gender is required"),

  //   // registration_date: yup
  //   //   .string("Enter your Registration Date")
  //   //   .required("Registration Date is required"),
  //   // industry: yup
  //   //   .string("Enter your Industry")
  //   //   .required("Industry is required"),
  //   company_name: yup
  //     .string("Enter your Company Name")
  //     .required("Company Name is required"),
  //   // designation: yup
  //   //   .string("Enter your Designation")
  //   //   .required("Designation is required"),
  //   //   mobile_no: yup
  //   //     .string()
  //   //     .typeError("Mobile number must be a number")
  //   //     .required("Mobile Number is Required")
  //   //     .matches(/^\d{10}$/, "Number should be 10 digits."),
  //   //   dob: yup
  //   //     .date()
  //   //     .test("not-current-date", "Enter Valid Date of Birth", function (value) {
  //   //       if (!value) {
  //   //         // Handle case where value is not provided
  //   //         return false;
  //   //       }

  //   //       const currentDate = new Date();
  //   //       currentDate.setHours(0, 0, 0, 0); // Set time to midnight

  //   //       return value < currentDate; // Change this to <= to allow the current date
  //   //     })
  //   //     .required("Date Of Birth is required"),
  // });
  const validationSchema = yup.object({
    full_name: yup
      .string("Enter Full Name")
      .required("Full Name is required")
      .matches(
        /^[A-Za-z ]+$/,
        "First Name must contain only alphabetic characters"
      ),
    email_id: yup
      .string("Enter your Email ID")
      .required("Email is required")
      .test(
        "isValidEmail",
        "Email should contain valid characters, an '@' symbol",
        (value) => isValidEmail(value)
      ),
    category: yup
      .string("Enter your Category")
      .required("Category is required")
      .nullable(),
    designation: yup
      .string("Enter your Designation")
      .required("designation is required"),
    company_name: yup
      .string("Enter your Company Name")
      .required("Company Name is required"),
    mobile_number: yup
      .string("Enter your Mobile Number")
      .required("Mobile Number is required")
      .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits"),
    cha_license_number: yup.string("Enter CHA License Number").nullable(),
    // .required("CHA License Number is required")
    // .required("cha license number is required"),
    food_preference: yup
      .string("Enter your Food Preference")
      // .required("food preference is required")
      .nullable(),

    gst_number: yup
      .string("Enter you gst number")
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Invalid GST Number"
      )
      .length(15)
      .nullable(),
    // .required("GST Number is required"),
  });
  const handleGuestAdd = async (data, setSubmitting) => {
    try {
      console.log("values => ", data);
      setSubmitting(true);
      await Axios.post("/guest/add-guest", data);
      showAlert("success", "Guest added successfully.");
      navigate("/guest");
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    dispatch(onRoomList());
  }, []);

  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        ADD GUEST
      </Typography>
      <Card>
        <CardContent>
          <Formik
            validateOnChange={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              console.log("vales => ", data);
              handleGuestAdd(data, setSubmitting);
              // validationSchema
              //   .validate(data, { abortEarly: false })
              //   .then(() => {
              //   })
              //   .catch((validationErrors) => {
              //     console.error("Validation Errors:", validationErrors);
              //   });
            }}
          >
            {({ setFieldValue, isSubmitting, values, errors, touched }) => (
              <Form noValidate autoComplete="off">
                {console.log("errr = >", errors)}
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="full_name"
                      name="full_name"
                      label="Full Name *"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="designation"
                      name="designation"
                      label="Designation *"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="email_id"
                      name="email_id"
                      label="Email *"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={errors.category && touched.category}
                    >
                      <Autocomplete
                        fullWidth
                        size="small"
                        disablePortal
                        getOptionLabel={(option) => option}
                        options={category}
                        name="category"
                        onChange={(event, val) => {
                          setFieldValue("category", val);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            error={errors.category && touched.category}
                            {...params}
                            label="Category"
                          />
                        )}
                      />
                      {errors && errors.category && touched.category && (
                        <FormHelperText>{errors.category}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      required
                      id="company_name"
                      name="company_name"
                      label="Company Name"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      required
                      id="mobile_number"
                      name="mobile_number"
                      label="Mobile Number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      required
                      id="cha_license_number"
                      name="cha_license_number"
                      label="CHA License Number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      required
                      id="gst_number"
                      name="gst_number"
                      label="GST Number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={errors.category && touched.category}
                    >
                      <Autocomplete
                        fullWidth
                        size="small"
                        disablePortal
                        getOptionLabel={(option) => option}
                        options={foodPreference}
                        name="food_preference"
                        onChange={(event, val) => {
                          setFieldValue("food_preference", val);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            error={
                              errors.food_preference && touched.food_preference
                            }
                            {...params}
                            label="Food Preference"
                          />
                        )}
                      />
                      {errors &&
                        errors.food_preference &&
                        touched.food_preference && (
                          <FormHelperText>
                            {errors.food_preference}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} alignContent="center">
                    <FormControlLabel
                      style={{ padding: "0px", margin: "0px", height: "100%" }}
                      control={
                        <Switch
                          onChange={(e) => {
                            setFieldValue(
                              "status",
                              values?.status ? false : true
                            );
                          }}
                          defaultChecked={values?.status ? true : false}
                          color="primary"
                        />
                      }
                      label="Status"
                      name="status"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>

                <Grid container columnSpacing={3} mt={5}>
                  <Grid item xs={6} textAlign="right">
                    <LoadingButton
                      variant="contained"
                      size="medium"
                      type="submit"
                      loading={isSubmitting}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={6} textAlign="left">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure you want to cancel?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Yes",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result?.isConfirmed) {
                            navigate("/guest");
                          }
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default AddGuest;
